import contract from '../contracts/main_contract.json'
import timezz from 'timezz';

const Web3 = require('web3');

window.addEventListener('turbolinks:load', (_event) => {
  // timezz('.timer', {
  //   // date: new Date('2021-09-02 00:00'),
  //   date: "September 7, 2021 00:00:00"
  // });
  if(document.getElementById('check-status')){
    document.getElementById('check-status').addEventListener('click', function (e) {
      e.preventDefault()
      if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');
        getAccountToCheckStatus();
      }
    });
  }


  if (document.getElementById("registration_form") != null ) {
    if (typeof window.ethereum !== 'undefined') {
      console.log('MetaMask is installed!');
      getAccount();
      
    } else {
      document.getElementsByClassName("dont_have_metamask")[0].classList.remove('visually-hidden')
    }

    // Invisible recaptcha
    document.getElementById('submit-btn').addEventListener('click', function (e) {
      grecaptcha.execute();
    });

    var submitInvisibleRecaptchaForm = function () {
      document.getElementByClass("invisible-recaptcha-form").submit();
    };
  }

  async function getAccount() {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    window.web3 = new Web3(window.ethereum);
    document.getElementById("user_address").value = account;
    document.getElementById("user_name").readOnly = false
    document.getElementById("user_email").readOnly = false
    // let myContract = new web3.eth.Contract(contract, process.env.ADDRESS_CONTRACT);
    // myContract.methods.balances("0x4Ae392c271f0935a7C1f75bE87d08A168536Ff84").call({from: "0x4Ae392c271f0935a7C1f75bE87d08A168536Ff84"}, function(error, result){
    // myContract.methods.balances(account).call({from: account}, function(error, result){
    //   document.getElementById("user_collector").value = result == 0 ? false : true
    // });
    
  }
});

async function getAccountToCheckStatus() {
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  const account = accounts[0];
  window.web3 = new Web3(window.ethereum);
  window.location.href = "/users/" + account
  // let myContract = new web3.eth.Contract(contract, process.env.ADDRESS_CONTRACT);
  // myContract.methods.balances("0x4Ae392c271f0935a7C1f75bE87d08A168536Ff84").call({from: "0x4Ae392c271f0935a7C1f75bE87d08A168536Ff84"}, function(error, result){
  // myContract.methods.balances(account).call({from: account}, function(error, result){
  //   document.getElementById("user_collector").value = result == 0 ? false : true
  // });
  
}